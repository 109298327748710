import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiInputDatepicker } from '../src';
import { DatePickerInputExample } from './example';
import { UiBadge } from '@uireact/badge';
import { UiIcon } from '@uireact/icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uiinputdatepicker"
    }}>{`UiInputDatepicker`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`An input that lets the user select dates using a datepicker`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/flex @uireact/button @uireact/grid @uireact/menu @uireact/text @uireact/datepicker`}</p>
    </blockquote>
    <h2 {...{
      "id": "date-picker-input"
    }}>{`Date picker input`}</h2>
    <Playground __position={2} __code={'<div style={{ height: \'350px\' }}>\n  <UiInputDatepicker\n    label=\"Date\"\n    labelOnTop\n    placeholder=\"Select the date\"\n    highlightToday\n    onChange={value => {\n      console.log(value)\n    }}\n    icon={<UiIcon icon=\"CalendarLines\" />}\n    size=\"large\"\n    useDialogOnSmall\n    closeLabel=\"Done\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInputDatepicker,
      DatePickerInputExample,
      UiBadge,
      UiIcon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        height: '350px'
      }}>
    <UiInputDatepicker label="Date" labelOnTop placeholder="Select the date" highlightToday onChange={value => {
          console.log(value);
        }} icon={<UiIcon icon="CalendarLines" mdxType="UiIcon" />} size="large" useDialogOnSmall closeLabel="Done" mdxType="UiInputDatepicker" />
  </div>
    </Playground>
    <h2 {...{
      "id": "date-picker-with-initial-value"
    }}>{`Date picker with initial value`}</h2>
    <p>{`If a prop `}<inlineCode parentName="p">{`date`}</inlineCode>{` is passed then it will be used as the default value for the input and the date selected in the datepicker`}</p>
    <Playground __position={3} __code={'<div style={{ height: \'350px\' }}>\n  <UiInputDatepicker\n    label=\"Date\"\n    date={new Date(\'2024-01-01 00:00:00\')}\n    labelOnTop\n    placeholder=\"Select the date\"\n    highlightToday\n    onChange={value => {\n      console.log(value)\n    }}\n    icon={<UiIcon icon=\"CalendarLines\" />}\n    size=\"large\"\n    useDialogOnSmall\n    closeLabel=\"Done\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInputDatepicker,
      DatePickerInputExample,
      UiBadge,
      UiIcon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        height: '350px'
      }}>
    <UiInputDatepicker label="Date" date={new Date('2024-01-01 00:00:00')} labelOnTop placeholder="Select the date" highlightToday onChange={value => {
          console.log(value);
        }} icon={<UiIcon icon="CalendarLines" mdxType="UiIcon" />} size="large" useDialogOnSmall closeLabel="Done" mdxType="UiInputDatepicker" />
  </div>
    </Playground>
    <h2 {...{
      "id": "date-picker-input-example-with-validation"
    }}>{`Date picker input example with validation`}</h2>
    <Playground __position={4} __code={'<DatePickerInputExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInputDatepicker,
      DatePickerInputExample,
      UiBadge,
      UiIcon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePickerInputExample mdxType="DatePickerInputExample" />
    </Playground>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const DatePickerInputExample: React.FC = () => {
  const [dateSelected, setDateSelected] = useState<Date | undefined>();
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onChange = useCallback(
    (date) => {
      setDateSelected(date);
      setSuccessMessageVisible(false);
      setErrorMessage('');
    },
    [setDateSelected]
  );

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!dateSelected) {
        setSuccessMessageVisible(false);
        setErrorMessage('Please select a date');
      } else {
        const today = new Date();
        const selectedDate = new Date(dateSelected);

        if (today < selectedDate) {
          setSuccessMessageVisible(true);
          setErrorMessage('');
        } else {
          setSuccessMessageVisible(false);
          setErrorMessage('Please select a date in the future');
        }
      }
    },
    [dateSelected]
  );

  return (
    <div style={{ height: '350px' }}>
      <UiText>Select a date in the future, if not an error will be shown:</UiText>
      <UiSpacing padding={formSpacing}>
        <form onSubmit={onSubmit}>
          <UiInputDatepicker
            label="Date"
            labelOnTop
            placeholder="Select a future date"
            onChange={onChange}
            icon={<UiIcon icon="CalendarLines" />}
            size="large"
            closeLabel="Done"
            error={errorMessage}
            category={errorMessage ? 'error' : undefined}
            showNextMonth
          />
          <UiSpacing padding={buttonMargin}>
            <UiButton type="submit" category="tertiary">
              <UiSpacing padding={buttonPadding}>
                <UiText>Submit</UiText>
              </UiSpacing>
            </UiButton>
          </UiSpacing>
        </form>
      </UiSpacing>
      {successMessageVisible && (
        <UiCard category="positive">
          <UiText>
            <UiIcon icon="CheckCircle" /> The date is valid
          </UiText>
        </UiCard>
      )}
    </div>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiInputDatepicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      